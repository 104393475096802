
import BaseTablePanel from '@/frame/share/table-panel'
import ExpendSearchPanel from '@/frame/share/expend-search-panel.vue'
import TablePanel from '@/frame/share/table-panel.vue'
import { Component } from 'vue-property-decorator'
import tableConfig from './index-table.config'
import _ from 'lodash'
import {
  PagerQueryBean,
  PageListData,
  PageDataApiResult
} from '@/libs/http-request'
import { PageInfo } from '@/libs/api-request'
import TeamResourceApi, {
  TeamResource
} from '@/api/maintain/resource-management/team-resource'
import { getterUserInfo } from '@/store'

const defaultQuery = {
  page: 1,
  pageSize: 10,
  sorts: []
}
@Component({
  name: 'ResourceManagementDataList',
  components: { ExpendSearchPanel, TablePanel }
})
export default class ResourceManagementDataList extends BaseTablePanel<TeamResource> {
  // 当前状态
  loading = false
  /// 表格定义
  tableConfig = tableConfig
  /// 查询数据
  queryData: PagerQueryBean = _.cloneDeep(defaultQuery)

  /**
   * 页面数据
   */
  pageData: PageListData<TeamResource> = {
    items: [],
    total: 0,
    pageSize: 10
  }

  get userInfo () {
    return getterUserInfo()
  }

  get canEdit () {
    return !!this.userInfo?.canOperateResource
  }

  created () {
    this.doPullDataList()
  }

  /**
   * 收缩搜索条
   */
  shrinkSeachbar () {
    const newQuery = _.cloneDeep(defaultQuery)
    if (!_.isEqual(newQuery, this.queryData)) {
      this.$nextTick(() => {
        this.queryData = newQuery
        this.doPullDataList()
      })
    }
  }

  /**
   * 工具栏按钮事件
   */
  doToolBarButtonAction (code: string) {
    this.toolBarButtonAction(
      code,
      (this.$refs.tablePanel as any).getTableObject()
    )
  }

  /**
   * 页面数据拉取
   */
  doPullDataList (pageInfo?: PageInfo) {
    pageInfo = pageInfo || { curPage: 1, pageSize: 10 }

    const that = this

    this.pullDataList(pageInfo).then(response => {
      that.pageData = response
    })
  }

  /**
   * 从服务器拉取数据
   */
  onPullDataListFromServer (
    pagerQueryBean: PagerQueryBean
  ): Promise<PageDataApiResult<TeamResource>> {
    return TeamResourceApi.query(pagerQueryBean)
  }

  /** 删除 */
  deleteItemById (id: number) {
    this.$Modal.confirm({
      title: '删除警告',
      content: '确认要删除当前资源配置项吗？',
      onOk: () => {
        TeamResourceApi.deleteItemByIds([id])
          .then(() => {
            this.doPullDataList()
            this.$Message.success('删除成功')
          })
          .catch(err => {
            this.$Message.error('删除失败: ' + err)
          })
      }
    })
  }
}
